.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #09d3ac;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: lightgrey;
}

#date {
    text-align: center;
}


.stripe h3 { 
    text-align: left;
    margin-bottom: 20px;
}


.stripe .btn-primary {
        width: 100%;
    background: #E9B359;
    color: white;
    padding: 16px 0px;
    border: 0px;
    border-radius: 10px;
 

    font-size: 20px !important;
    padding: 22px 0 !important;
    margin-left: 0px !important;
}



.stripe {
    border: 1px solid lightgray;
    padding: 40px;
    background: white;
    border-radius: 15px;
    padding-bottom: 80px;
}

.stripe__data {
    max-width: 400px;
    margin: auto;
}

.stripe__type {
        border: 1px solid #efece8;
    
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
          margin-bottom: 20px;
}

.stripe__type > div {
        width: 50%;
}

.stripe__type > div > label {
    width: 100%;
    display: inline-block;
        box-sizing: border-box;
        text-align: center;
}

.stripe__type,
.stripe__amount {
    display: flex;
    justify-content: space-between;
   
}


.stripe__amount  > div:not(:last-child) {
 
    margin-right: 5px;

    }

 



.stripe input[type="text"]:placeholder {
    color: white;
}

.stripe input[type="radio"] {
    display: none;
}

.stripe input[type="radio"]+label {
    cursor: pointer;
    padding: 10px 24px;
    border: 1px solid #EFECE8;
    border-radius: 10px;
     font-size: 20px;
          display: block;
}

.stripe__type input[type="radio"]+label {
    cursor: pointer;
    padding: 12px 20px;
       border: 3px solid transparent;
    border-radius: 10px;
   font-size: 20px;
    margin-bottom: 0px;
}

.stripe input[type="radio"]:checked+label {
       border: 3px solid #E9B359;
    background: #FCF9F4;
     border-radius: 10px;
        font-size: 20px;
}

.stripe input[type="radio"]+label:not(:last-child)
{
    margin-right: 16px;
}

.stripe .custom-price-button label {
    margin-bottom: 10px;
    width: 100%; 
    box-sizing: border-box;
}

.stripe .custom-price-input {
    margin-bottom: 20px;
}

.stripe .custom-price-input.closed {
    height: 0px;
    overflow: hidden;
    transition: 0.3s;
}

.stripe .custom-price-input.active {
    height: auto;
    transition: 0.3s;
}

.stripe .custom-price-input input:disabled {

    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
}

.stripe select {
    cursor: pointer;
    padding: 16px 0px;
    border: 3px solid #E9B359;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 15px; 
    text-align: center;
    background: #FCF9F4;
     margin-bottom: 40px;
     font-size: 20px;
    color: black;
    font-weight: bold;
}

.stripe .custom-price-input input,
.stripe .payment-description input {

    width: 100%;
    box-sizing: border-box;

    border: none;
    border-bottom: 1px solid lightGrey;
    margin-bottom: 7px;
    margin-top: 5px;
    min-height: 30px;
    padding-left: 0px;
    height: 40px;
    border-radius: 0px;
    font-family: "PT Sans",
        sans-serif;
   font-size: 20px;


    height: auto;
    visibility: visible;
    opacity: 1;
    transition: 0.3s;
}

.stripe .custom-price-input input:focus-visible,
.stripe .payment-description input:focus-visible {
    outline: none;
} 

.stripe .payment-description {
    margin-bottom: 20px;
}

.stripe-p {
    text-align: center;
    margin-top: 40px;
    font-weight: bold;
    color: red;
    text-shadow: 2px 2px #cdcdcdcc;
    -webkit-text-stroke: 0.3px white;
    font-size: 20px;
}

/* preloader */

.preloader {
  padding-top: 25px;
  margin: auto;
  display: block;
  width: 80px;
  height: 80px;
}

.preloader:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #36669a;
  border-color: #36669a transparent #36669a transparent;
  animation: preloader 1.2s linear infinite;
}

@keyframes preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



    @media (min-width: 361px) and (max-width: 530px){
        .stripe {
            padding: 40px 15px 80px;
        }

        .stripe input[type=radio]+label {
                padding: 10px 10px;
                text-align: center;
        }

        .stripe__amount  > div { 
        width: calc(25% - 5px);
    }
    }

@media (max-width: 360px) {
    .stripe__amount {
        flex-wrap: wrap;
    }

.stripe__amount  > div {
    margin-right: 5px;
        width: calc(50% - 5px);
        margin-bottom: 10px;
}
}


.donateButton {
    display: block !important;
}